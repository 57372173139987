<template>
  <div>
    <main>
      <section class="top-zw">
        <!-- <div class="container">
          <div class="title">
            <span>10000+</span>商户<br />都在用<span>店教授!</span>
          </div>
          <div class="text1">全业态全场景适用 店教授与优秀商户一起高速成长</div>
          <div class="text1 phone">
            全业态全场景适用<br />店教授与优秀商户一起高速成长
          </div>
          <a href="http://pos.yunlankeji.com/" target="_blank" class="free">免费试用</a>
        </div> -->
      </section>
      <!-- 内容 -->
      <section class="case-content w1200">
        <div class="container">
          <!-- 类别 -->
          <div class="category">
            <span :class="index == activeIndex?'category-item active':'category-item'" v-for="(item,index) in typeList" :key="index" @click="changeItem(item,index)">{{item}}</span>
          </div>
          <div id="OtherCasesArea">
            <div class="friend" v-if="type == '全部' || type == '批发'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_1.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">百联超美</div>
                  <div class="f16" style="color: #888">批发</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">提升79%的运营效率</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">79%</p>
                    <p class="f18" style="color: #888">运营效率提升</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">30%</p>
                    <p class="f18" style="color: #888">营业额提升</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授供应链</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授云财务</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '母婴'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/muyin.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">SEVEN TREES</div>
                  <div class="f16" style="color: #888">母婴</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">客户满意 每日客单数提升10%</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">提升50%</p>
                    <p class="f18" style="color: #888">顾客好评率</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">提成10%</p>
                    <p class="f18" style="color: #888">每日客单数</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">积分商城</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '零售'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_2.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">奥莱华联</div>
                  <div class="f16" style="color: #888">零售</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">当网红零食遇上AI技术</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">79%</p>
                    <p class="f18" style="color: #888">运营效率提升</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">30%</p>
                    <p class="f18" style="color: #888">营业额提升</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">零售AI收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">会员管理</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">智能盘点</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '生鲜'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_3.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">陶果屋</div>
                  <div class="f16" style="color: #888">生鲜</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">532家社区生鲜门店迅速扩张！</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">532家</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">0.2S</p>
                    <p class="f18" style="color: #888">识别</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">AI称重收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="friend" v-if="type == '全部' || type == '茶饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/index/Frame_22.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">提饮</div>
                  <div class="f16" style="color: #888">茶饮</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">个性化礼品卡助力高效裂变！</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">4倍速</p>
                    <p class="f18" style="color: #888">会员积累</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">85%</p>
                    <p class="f18" style="color: #888">小程序点单率</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_5.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">南翔小笼</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">小坪门店的智慧餐饮场景搭建！</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">10万/坪</p>
                    <p class="f18" style="color: #888">高坪效</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">95%</p>
                    <p class="f18" style="color: #888">自助点餐率</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">扫码点餐</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">第三方外卖平台1111</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_7.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">海峰私厨</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">传统餐企升级为现代信息化餐企！</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">463家</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">3倍</p>
                    <p class="f18" style="color: #888">运营效率</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">连锁管理</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">商品管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_8.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">不二家米粉</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">小程序单量增长超300%</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">300%</p>
                    <p class="f18" style="color: #888">销量增长</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">80%</p>
                    <p class="f18" style="color: #888">线上单量占比</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">后厨KDS</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">会员管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_9.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">川味金记</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">小程序单量增长超300%</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">300%</p>
                    <p class="f18" style="color: #888">销量增长</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">80%</p>
                    <p class="f18" style="color: #888">线上单量占比</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">后厨KDS</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">会员管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_10.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">衢州小吃</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">小程序单量增长超300%</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">300%</p>
                    <p class="f18" style="color: #888">销量增长</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">80%</p>
                    <p class="f18" style="color: #888">线上单量占比</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">后厨KDS</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">会员管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_11.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">鳗道</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">小程序单量增长超300%</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">300%</p>
                    <p class="f18" style="color: #888">销量增长</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">80%</p>
                    <p class="f18" style="color: #888">线上单量占比</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">后厨KDS</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">会员管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '餐饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/case_12.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <p class="f32">点个火锅</p>
                  <p class="f16" style="color: #888">餐饮</p>
                </div>
              </div>
              <div class="middle">
                <div class="f26">小程序单量增长超300%</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">300%</p>
                    <p class="f18" style="color: #888">销量增长</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">80%</p>
                    <p class="f18" style="color: #888">线上单量占比</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">后厨KDS</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">会员管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '茶饮'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/index/Frame_22.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">茶猩星</div>
                  <div class="f16" style="color: #888">茶饮</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">全业务流程智能化</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">532家</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">0.2S</p>
                    <p class="f18" style="color: #888">识别</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">AI称重收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '生鲜'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/index/Frame_22.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">肆季鲜生</div>
                  <div class="f16" style="color: #888">生鲜</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">社区团购一天营收18万</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">18万</p>
                    <p class="f18" style="color: #888">营收额</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">1000+</p>
                    <p class="f18" style="color: #888">新增会员</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">社区团购</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '宠物'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/chongwu1.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">小佩宠物</div>
                  <div class="f16" style="color: #888">宠物</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">智能一体化宠物店！</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">14家</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">5种+</p>
                    <p class="f18" style="color: #888">宠物业态</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授合伙人</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '宠物'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/yipei.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">宠儿宠物</div>
                  <div class="f16" style="color: #888">宠物</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">疫情期间实现无接触运营</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">20家</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">10000+</p>
                    <p class="f18" style="color: #888">有效会员</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">活体管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '艺培'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/chongwu.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">泫舞舞蹈</div>
                  <div class="f16" style="color: #888">艺培</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">连锁门店信息化先行者</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">500+</p>
                    <p class="f18" style="color: #888">学员</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">60%</p>
                    <p class="f18" style="color: #888">资源利用率</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">营销功能</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '美业'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/meiye1.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">黑白秀</div>
                  <div class="f16" style="color: #888">美业</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">30+门店实现线上线下一体化</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">60+</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">0秒</p>
                    <p class="f18" style="color: #888">响应延迟</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '美业'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/meiye2.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">伊美娜</div>
                  <div class="f16" style="color: #888">美业</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">品牌全方位迈向门店数字化</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">15家</p>
                    <p class="f18" style="color: #888">门店</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">0秒</p>
                    <p class="f18" style="color: #888">响应延迟</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '服装'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/meiye2.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">欧诗雨</div>
                  <div class="f16" style="color: #888">服装</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">完美解决家纺店库存问题！</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">1提升50%</p>
                    <p class="f18" style="color: #888">顾客好评率</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">80%</p>
                    <p class="f18" style="color: #888">经营效率提高</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">门店收银</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">商品管理</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">货流管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="friend" v-if="type == '全部' || type == '烘焙'">
              <div class="top">
                <div style="margin-right: 10px">
                  <img
                    src="../../assets/images/cases/meiye2.png"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div class="f32">九记甜品</div>
                  <div class="f16" style="color: #888">烘焙</div>
                </div>
              </div>
              <div class="middle">
                <div class="f26">一场活动超200%储值预期</div>
                <div class="data">
                  <div style="text-align: center">
                    <p class="f24" style="color: #f68589">10000+</p>
                    <p class="f18" style="color: #888">会员存值</p>
                  </div>
                  <div style="text-align: center">
                    <p class="f26" style="color: #f68589">超200%</p>
                    <p class="f18" style="color: #888">储值预期</p>
                  </div>
                </div>
              </div>
              <div class="bot">
                <div class="f26">店教授亮点产品</div>
                <div class="product">
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">店教授小程序</p>
                  </div>
                  <div style="text-align: center" class="bgr">
                    <p class="f16" style="color: #888">花样营销</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 店教授帮助各行业商户走向成功 -->
      <section class="industry w1200">
        <div class="container">
          <div class="title-box">店教授帮助各行业商户走向成功</div>
          <div class="row industry-box">
            <div class="imagelazyBox textc pb60">
              <el-image
                v-for="url in imgurls"
                :key="url"
                :src="url"
                class="imageItem"
              ></el-image>
            </div>
          </div>
        </div>
      </section>
      <!-- 有任何问题，找我们！ -->
      <section class="contact">
        <div class="container">
          <div class="contact-title w1200">
            <p class="f64" style="margin-right:40px">有任何问题，找我们！</p>
            <p class="f24">答您所虑，解您所忧！</p>
          </div>
          <!-- <div class="contact-item">
            <div class="row">
              <div class="col-lg-7 left">没有店教授账号，想试用产品？</div>
              <div class="col-lg-5 right pc">
                <a href="" class="">30天免费试用</a>
              </div>
              <div class="col-lg-5 right phone">
                <a href="" class="">30天免费试用</a>
              </div>
            </div>
          </div> -->
          <div class="contact-item w1200">
            <div class="row">
              <div class="f36">没有店教授账号，想咨询问题？</div>
              <div class="right">
                <div class="cs">
                  <p class="f24" style="margin-bottom:20px;margin-right:20px">电话咨询 </p>
                  <a href="">400-066-0526</a>
                </div>
                <!-- <div class="cs">
                  <p class="f24" style="margin-right:20px">微信咨询 </p>
                  <a target="_blank" href="" class="pospalCount" countaction="">
                    400-066-0526
                  </a>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="contact-item w1200">
            <div class="row">
              <div class="f36">有店教授账号，想咨询问题？</div>
              <div class="col-lg-5 right">
                <div class="ewm">
                  <img src="../../assets/images/common/code.png" alt="" />
                  <text>扫一扫，联系售后</text>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// import "../../assets/css/plan/fresh/bootStrap.css";
// import "../../assets/css/casesTwo/tw.css";
import "../../assets/js/jquery/jquery.js";
export default {
  data() {
    return {
      imgurls: [
        require("../../assets/images/index/Frame_1.png"),
        require("../../assets/images/index/Frame_2.png"),
        require("../../assets/images/index/Frame_3.png"),
        require("../../assets/images/index/Frame_4.png"),
        require("../../assets/images/index/Frame_5.png"),
        require("../../assets/images/index/Frame_6.png"),
        require("../../assets/images/index/Frame_7.png"),
        require("../../assets/images/index/Frame_8.png"),
        require("../../assets/images/index/Frame_9.png"),
        require("../../assets/images/index/Frame_10.png"),
        require("../../assets/images/index/Frame_11.png"),
        require("../../assets/images/index/Frame_12.png"),
        require("../../assets/images/index/Frame_13.png"),
        require("../../assets/images/index/Frame_14.png"),
        require("../../assets/images/index/Frame_15.png"),
        require("../../assets/images/index/Frame_16.png"),
        require("../../assets/images/index/Frame_17.png"),
        require("../../assets/images/index/Frame_18.png"),
        require("../../assets/images/index/Frame_19.png"),
        require("../../assets/images/index/Frame_20.png"),
        require("../../assets/images/index/Frame_21.png"),
        require("../../assets/images/index/Frame_22.png"),
        require("../../assets/images/index/Frame_23.png"),
        require("../../assets/images/index/Frame_24.png"),
        require("../../assets/images/index/Frame_25.png"),
        require("../../assets/images/index/Frame_26.png"),
        require("../../assets/images/index/Frame_27.png"),
        require("../../assets/images/index/Frame_28.png"),
        require("../../assets/images/index/Frame_29.png"),
        require("../../assets/images/index/Frame_30.png"),
        require("../../assets/images/index/Frame_31.png"),
        require("../../assets/images/index/Frame_32.png"),
        require("../../assets/images/index/Frame_33.png"),
        require("../../assets/images/index/Frame_34.png"),
        require("../../assets/images/index/Frame_35.png"),
        require("../../assets/images/index/Frame_36.png"),
        require("../../assets/images/index/Frame_37.png"),
        require("../../assets/images/index/Frame_38.png"),
        require("../../assets/images/index/Frame_39.png"),
        require("../../assets/images/index/Frame_40.png"),
        require("../../assets/images/index/Frame_41.png"),
        require("../../assets/images/index/Frame_42.png")
      ],
      typeList:[
        '全部','零售','餐饮','茶饮','服装','母婴','生鲜','烘焙','宠物','美业','艺培','批发'
      ],
      type:'全部',
      activeIndex: 0
    };
  },
  methods:{
    changeItem(type,index){
      console.log(type);
      this.type = type
      this.activeIndex = index
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
.title-box {
  text-align: center;
  font-size: 32px;
  color: #333;
}
main {
  background: #f6f7fb;
}
main .top-zw {
  background-image: url("../../assets/images/cases/anliBanner.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 800px;
}
main .top-zw .container {
  padding-top: 10%;
  padding-left: 20%;
  color: #fff;
}
main .top-zw .container .free{
  display: block;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #f67253;
  border-radius: 10px;
  color: #fff;
  font-size: 24px;

}
main .top-zw .container .title {
  font-size: 98px;
  font-family: PingFang SC;
  line-height: 120px;
}
main .top-zw .container .title span {
  color: #f67253;
}
main .top-zw .container .text1 {
  padding: 80px 0 80px 0;
  font-size: 20px;
}
main .top-zw .container .text1.phone {
  display: none;
}
main .case-content {
  padding: 100px 0;
}
main .case-content .category {
  height: 100px;
  background: #edeff3;
  border-radius: 12px;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: scroll;
}
main .case-content .category-item {
  text-decoration: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
  border-right: 1px solid #ccc;
  padding: 0 20px;
  color: #000;
}
main .case-content .category-item:hover {
  color: #f67253;
}
main .case-content .category-item:last-child {
  border-right: none;
}
main .case-content .category-item.isClick {
  color: #f67253;
}
main .case-content .paging {
  margin-top: 50px;
  height: 60px;
  background: #edeff3;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .case-content .paging-item {
  padding: 0 20px;
  cursor: pointer;
  color: #333;
}
main .case-content .paging-item:hover {
  color: #f67253;
}
main .case-content .paging-item:visited {
  color: #f67253;
}
main .case-content #OtherCasesArea {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
main #OtherCasesArea .friend {
  width: 30%;
  height: 515px;
  padding: 42px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px 0px rgba(25, 35, 69, 0.12);
  margin-top: 40px;
  border-radius: 20px;
  background-color: #fff;
}
main #OtherCasesArea .friend .top {
  height: 30%;
  display: flex;
  border-bottom: 1px solid #ccc;
}
main #OtherCasesArea .friend .middle {
  height: 30%;
  margin-top: 30px;
}
main #OtherCasesArea .friend .middle .data {
  margin-top: 10px;
  height: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f9fc;
  border-radius: 10px;
}
main #OtherCasesArea .friend .middle .data p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
main #OtherCasesArea .friend .bot {
  margin-top: 50px;
}
main #OtherCasesArea .friend .bot .product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
main #OtherCasesArea .friend .bot .product .bgr {
  padding: 10px 5px;
  width: 30%;
  background-color: #f8f9fc;
  border-radius: 5px;
}
main #OtherCasesArea .friend .bot .product .bgr p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-top: 10px; */
}

main .industry {
  padding-bottom: 80px;
}
main .industry .container {
  background: #fff;
  border-radius: 12px;
}
main .industry .container .title-box {
  padding: 85px 0 32px 0;
}
main .industry .container .industry-box {
  border-top: 1px solid #f0f3f8;
  width: 90%;
  margin: 0 auto;
  padding-top: 87px;
}
main .industry .container .industry-box-item {
  text-align: center;
  margin-bottom: 59px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
main .industry .container .industry-box-item img {
  width: 150px;
  height: 126px;
  margin-right: 40px;
}
.imagelazyBox .imageItem:hover {
  transform: translateY(-6px) scale(1.1);
}
.contact{
  height: 600px;
  background-color: #1d1e22;
  color: #fff;
  padding-top: 50px;
  box-sizing: border-box;
}
.contact .container{
  height: 100%;
}
.contact-title{
  margin: 0 auto;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-bottom: 40px;
}
.contact-item{
  margin: 0 auto;
  height: 30%;
  background-color: #000;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.contact-item .row{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contact-item .row .cs{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.contact-item .row .cs a{
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 200px;
  border: 1px solid #f67253;
  border-radius: 5px;
  color: #f67253;
  font-size: 24px;
  font-weight: bold;
  margin-bottom:10px;
}
.active{
  color:#f67253 !important;
}
</style>